<template>
  <v-dialog v-model="showDialog" width="600px">
    <v-card flat>
      <Loading :value="loading" />
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>
          <h3>{{ $t('SWITCH_ACCOUNT.SWITCH_ACCOUNT_TITLE') }}</h3>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />

      <v-form ref="formAccount" v-model="validFormAccount">

        <v-card-text class="pb-0 pr-6 pl-6">
          <v-select
            :items="companies"
            label="Company"
            hide-details
            outlined
            @change="loadOffices"
          />
        </v-card-text>

        <v-card-text class="pb-0 pr-6 pl-6">
          <v-select
            :items="offices"
            label="Office"
            outlined
            hide-details
          />
        </v-card-text>

        <v-card-actions class="justify-center pb-6">
          <v-btn
            color="accent"
            :disabled="!validFormAccount && !loading"
            x-large
            @click="validateAccount"
          >
            <span>{{ $t('SWITCH_ACCOUNT.SWITCH_ACCOUNT_BTN') }}</span>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>

</template>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
</style>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'DialogSwitchAccount',
  components: {
    Loading
  },
  props: ['value'],
  data: () => ({
    loading: false,
    selectedCompany: null,
    selectedOffice: null,
    companies:[],
    offices:[],
    validFormAccount: false
  }),

  computed: {
    showDialog () {
      return this.value
    }
  },

  watch: {
    value (n) {
      if (n) this.loadCompanies()
    }
  },
  methods: {

    loadCompanies () {
      this.loading = true
      this.$services.api_user.company.search()
        .finally(() => {
          this.loading = false
        })
    },

    loadOffices (id) {
      this.$services.api_user.office.search()
    },

    close () {
      this.$emit('close')
    },

    validateAccount () {

    }
  }
}
</script>
